import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import USerProfileImage from "../../images/noun_User_1819851.png";
import locationsymbol from "../../images/loc_App.png";
import Translation from "../../language/translate";
import Autocomplete from "react-google-autocomplete";
import {
  getAllIdentificationTypes,
  logoutApp,
} from "../../actions/loginactions";
import { useHistory } from "react-router";
import {
  coustomerProfileDetails,
  updateUserDetails,
} from "../../actions/editProfileDetailsAndNumberAction";
import { ClipLoader } from "react-spinners";
import "./index.css";
import { Select } from "antd";

let fullname = React.createRef();
let emailid = React.createRef();

const EditProfile = (props) => {
  const history = useHistory();

  const details = useSelector((state) => state);
  const [loader, setloader] = useState(false);
  const [profileDetails, setProfileDetails] = useState("");
  const [location, setLocation] = useState("");
  const [addressDetails, setAdressDetails] = useState("");
  const [accountdetails, setcreateaccountmodule] = useState({
    nameerror: "",
    emailerror: "",
    locationerror: "",
    customer_identification_code: "",
    customer_identification: "",
  });
  const [identificationDetails, setIdentificationDetails] = useState({
    customer_identification: "",
    customer_identification_code: undefined,
  });
  const [identificationTypeList, setIdentificationTypeList] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    setloader(true);
    onLoad();
    fetchIdentificationTypes();
  }, []);

  const fetchIdentificationTypes = async () => {
    const response = await dispatch(
      getAllIdentificationTypes({ token: localStorage.getItem("user_token") })
    );
    if (response.data) {
      setIdentificationTypeList(
        response.data.data.map((type) => {
          return {
            label: type.name,
            value: type._id,
          };
        })
      );
    }
  };
  const updateIdentificationDetails = (name, value) => {
    setIdentificationDetails((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const fetchlocation = (place) => {
    console.log(place);
    // console.log(place.address_components[0].long_name)
    if (place.geometry) {
      setLocation({
        user_lcationcord: {
          type: "Point",
          coordinates: [
            place.geometry.location.lng(),
            place.geometry.location.lat(),
          ],
        },
        latitude: place.geometry.location.lat().toString(),
        longitude: place.geometry.location.lng().toString(),
        useraddress: place.formatted_address,
        userlocationcity: place.address_components.find((addr) => {
          return addr.types[0] == "locality" || addr.types[1] == "locality";
        }).long_name,
        userLocationCountry: place.address_components.find((addr) => {
          return addr.types[0] == "country";
        }).long_name,
      });
    } else {
      setLocation("");
    }
  };

  const onLoad = async () => {
    let obj = {
      customer_id: localStorage.getItem("customer_id"),
      mobile_number: localStorage.getItem("mobile_no"),
      deviceType: "web",
      token: localStorage.getItem("user_token"),
    };
    const profileDetailsResponse = await dispatch(coustomerProfileDetails(obj));
    console.log(profileDetailsResponse);
    if (profileDetailsResponse.data.status_code == 200) {
      setloader(false);
      setProfileDetails(profileDetailsResponse.data.details);
      setIdentificationDetails({
        customer_identification_code: profileDetailsResponse.data.details
          .customer_identification_code
          ? profileDetailsResponse.data.details.customer_identification_code
          : undefined,
        customer_identification: profileDetailsResponse.data.details
          .customer_identification
          ? profileDetailsResponse.data.details.customer_identification
          : "",
      });
      setLocation({
        user_lcationcord: {
          type: "Point",
          coordinates: [
            profileDetailsResponse.data.details.longitude,
            profileDetailsResponse.data.details.latitude,
          ],
        },
        latitude: profileDetailsResponse.data.details.latitude,
        longitude: profileDetailsResponse.data.details.longitude,
        useraddress: profileDetailsResponse.data.details.address,
        userlocationcity: profileDetailsResponse.data.details.city,
        userLocationCountry: profileDetailsResponse.data.details.country,
      });
      setAdressDetails("data");
    } else if (
      profileDetailsResponse.data.status_code == 408 ||
      profileDetailsResponse.data.status_code == 402
    ) {
      let obj = {
        customer_id: localStorage.getItem("customer_id"),
        deviceType: "web",
      };
      const logoutResponse = await dispatch(logoutApp(obj));
      if (logoutResponse.data.status_code == 200) {
        localStorage.clear();

        history.push({ pathname: "/" });
        window.location.reload();
      }
    } else {
      setloader(false);
    }
  };

  const updateProfile = async () => {
    let regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    setloader(true);
    if (fullname.current.value == "") {
      setcreateaccountmodule({
        nameerror: "Please enter the full name",
      });
      setloader(false);
    } else if (
      emailid.current.value == "" ||
      regex.test(emailid.current.value) == false
    ) {
      setcreateaccountmodule({
        emailerror: Translation[details.Languages]["Validemail"],
      });
      setloader(false);
    } else if (addressDetails === "") {
      setcreateaccountmodule({
        locationerror: "Please enter your location",
      });
      setloader(false);
    } else if (location === "") {
      setcreateaccountmodule({
        locationerror: "Selected location is not proper",
      });
      setloader(false);
    } else {
      setcreateaccountmodule({
        nameerror: "",
        emailerror: "",
        locationerror: "",
      });
      setloader(false);
      let obj = {
        customer_id: localStorage.getItem("customer_id"),
        name: fullname.current.value,
        email: emailid.current.value,
        mobile_number: profileDetails.mobile_number,
        country: location.userLocationCountry,
        city: location.userlocationcity,
        gender: profileDetails.gender,
        dob: profileDetails.dob,
        location: {
          type: "Point",
          coordinates: [
            parseFloat(location.longitude),
            parseFloat(location.latitude),
          ],
        },
        address: location.useraddress,
        latitude: location.latitude,
        longitude: location.longitude,
        profile_image: "",
        pincode: "",
        design_interested_in: [],
        token: localStorage.getItem("user_token"),
        customer_identification: identificationDetails.customer_identification,
        customer_identification_code:
          identificationDetails.customer_identification_code
            ? identificationDetails.customer_identification_code
            : "",
      };
      const updateUserDetailsResponse = await dispatch(updateUserDetails(obj));
      if (updateUserDetailsResponse.data.status_code == 200) {
        localStorage.setItem(
          "full_name",
          updateUserDetailsResponse.data.details.name
        );
        localStorage.setItem(
          "email_id",
          updateUserDetailsResponse.data.details.email
        );
        localStorage.setItem(
          "location_lat",
          updateUserDetailsResponse.data.details.location.coordinates[1]
        );
        localStorage.setItem(
          "location_long",
          updateUserDetailsResponse.data.details.location.coordinates[0]
        );
        localStorage.setItem(
          "location_address",
          updateUserDetailsResponse.data.details.address
        );
        localStorage.setItem(
          "location_country",
          updateUserDetailsResponse.data.details.country
        );
        localStorage.setItem(
          "location_city",
          updateUserDetailsResponse.data.details.city
        );
        props.handleClose();
        setloader(false);
      } else if (
        updateUserDetailsResponse.data.status_code == 408 ||
        updateUserDetailsResponse.data.status_code == 402
      ) {
        let obj = {
          customer_id: localStorage.getItem("customer_id"),
          deviceType: "web",
        };
        const logoutResponse = await dispatch(logoutApp(obj));
        if (logoutResponse.data.status_code == 200) {
          localStorage.clear();

          history.push({ pathname: "/" });
          window.location.reload();
        }
      }
    }
  };

  const adressDetailsOnChange = (e) => {
    setAdressDetails(e.target.value);
  };

  return (
    <React.Fragment>
      {loader ? (
        <div className="loader_center">
          <ClipLoader color={"#242E49"} loading={loader} />
        </div>
      ) : (
        <div align={details.Languages == "ar" ? "right" : ""}>
          <div className="row welcome-div">
            <div className="col-2 editprf_resp" style={{ paddingTop: "10px" }}>
              <img src={USerProfileImage} alt="" />
            </div>
            <div className="col-10 prfdet_resp">
              <div className="welcome-name">
                {Translation[details.Languages]["WelcomeCap"]}{" "}
                {profileDetails.name}
              </div>
              <div className="welcome-text">
                {Translation[details.Languages]["Profileinfo"]}
              </div>
            </div>
          </div>
          <div className="row" style={{ paddingTop: "6%" }}>
            <div className="col-12 col-md-6">
              <div className="input-heading">
                {Translation[details.Languages]["Fullnametext"]}
              </div>
              <input
                type="text"
                placeholder="Enter your full name"
                className="input-field-class"
                ref={fullname}
                defaultValue={profileDetails.name}
              />
              <div className="error-div">{accountdetails.nameerror}</div>
            </div>
            <div className="col-12 col-md-6">
              <div className="input-heading">
                {Translation[details.Languages]["EmailIDtext"]}
              </div>
              <input
                type="text"
                placeholder="Enter Email ID"
                className="input-field-class"
                ref={emailid}
                defaultValue={profileDetails.email}
              />
              <div className="error-div">{accountdetails.emailerror}</div>
            </div>
            <div className="col-12 col-md-6" style={{ paddingTop: "20px" }}>
              <div className="input-heading">Customer Identification Type</div>
              <Select
                value={identificationDetails.customer_identification_code}
                placeholder={"Customer Identification Type"}
                onChange={(value) => {
                  updateIdentificationDetails(
                    "customer_identification_code",
                    value
                  );
                }}
                className="budget-select border-0 mb-4 pt-2 text-left w-100 mt-2"
                options={identificationTypeList}
                style={{ height: " 50px " }}
              ></Select>
              <div className="error-div">
                {accountdetails.customer_identification_code}
              </div>
            </div>
            <div className="col-12 col-md-6" style={{ paddingTop: "20px" }}>
              <div className="input-heading">Customer Identification Code</div>
              <input
                type="text"
                placeholder="Enter Identification Code"
                className="input-field-class"
                value={identificationDetails.customer_identification}
                onChange={(e) => {
                  updateIdentificationDetails(
                    "customer_identification",
                    e.target.value
                  );
                }}
              />
              <div className="error-div">
                {accountdetails.customer_identification}
              </div>
            </div>
            <div className="col-12" style={{ paddingTop: "20px" }}>
              <div className="input-heading">
                {Translation[details.Languages]["DeliveryFittingAddress"]}
              </div>
              <Autocomplete
                className="input-field-class"
                onPlaceSelected={fetchlocation}
                onChange={(e) => adressDetailsOnChange(e)}
                defaultValue={location.useraddress}
                types={[]}
                componentRestrictions={{
                  country: ["ae", "sa", "qa", "om", "kw", "bh", "in"],
                }}
              />
              <div className="location_symbol_wrap">
                <img className="" src={locationsymbol} alt="" />
              </div>
              <div className="error-div">{accountdetails.locationerror}</div>
            </div>
            <div className="row button-wrap-div">
              <div className="col-lg-6 col-md-6 col-sm-12 go-back-wrap">
                <div
                  className={
                    details.Languages == "en"
                      ? "go-back-button"
                      : "go-back-buttonarabic"
                  }
                  onClick={() => props.handleClose()}
                >
                  {Translation[details.Languages]["Cancel"]}
                </div>
              </div>
              {loader ? (
                <div className="loader_center">
                  <ClipLoader color={"#242E49"} loading={loader} />
                </div>
              ) : (
                <div
                  className="col-lg-6 col-md-6 col-sm-12 continue-wrap"
                  onClick={() => updateProfile()}
                >
                  <input
                    class="continue-button"
                    type="button"
                    value={Translation[details.Languages]["Update"]}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default EditProfile;
